import { enComparer, useScreenWidth } from 'hooks/useScreenWidth';
import { memo, useMemo } from 'react';

import { AnchorLink } from 'components/anchorLink';
import { Button } from 'components/button';
import Image from 'next/image';
import { RichText } from 'components/richText';
import { Wrapper } from 'components/wrapper';
import { breakpoint, isAustrianSite } from 'utility/constant';
import { isEqual } from 'lodash';
import styles from './BannerPromo.module.scss';
import { useGetUrlPromo } from 'hooks/useGetPromoUrl';
import { useRouter } from 'next/router';
import { getRouteByKey, PathName } from 'utility/routes';

export type BannerPromoProps = {
  title: string;
  cta?: {
    label: string;
    url: string;
  };
  images: {
    alt: string;
    landscape: { srcDesktop: string; srcMobile: string };
    portrait: { src: string };
  };
};

export const BannerPromo = memo(
  function BannerPromo({ title: text, images, cta }: BannerPromoProps) {
    const isMobile = useScreenWidth(enComparer.equal | enComparer.less, breakpoint.md);
    const router = useRouter();

    const registrationUrl = useMemo(() => getRouteByKey(PathName.registrationUrl), []);

    const slugProvenienza = useMemo(() => {
      const splittedSlug = router.asPath.split('/');
      const slug = splittedSlug.length > 1 ? splittedSlug[1] : splittedSlug[0];
      return slug || 'homepage';
    }, [router.asPath]);

    const { src, alt } = useMemo(() => {
      const { alt, landscape } = images ?? {};
      const { srcMobile, srcDesktop } = landscape ?? {};

      const src = isMobile ? srcMobile : srcDesktop ?? srcDesktop ?? srcMobile;
      return { src, alt };
    }, [images, isMobile]);

    const { href, target } = useGetUrlPromo(cta?.url ?? '', slugProvenienza);

    const isCtaRegisterDisable = useMemo(() => {
      return cta?.url.includes(registrationUrl) && isAustrianSite;
    }, [cta, registrationUrl]);

    return (
      <Wrapper bgColorTransparent noPaddingLeft noPaddingRight>
        <div className={styles.container}>
          {src && (
            <Image
              className={styles.image}
              src={src}
              alt={alt ?? ''}
              fill
              style={{ objectFit: 'cover', objectPosition: 'center' }}
            />
          )}
          {!isCtaRegisterDisable && (
            <div className={styles.textContainer}>
              <RichText containerTag="h2" containerClassName={styles.text}>
                {text}
              </RichText>
              {cta?.url ? (
                <AnchorLink
                  isButton
                  className={styles.button}
                  size="medium"
                  visualizationType="primary"
                  href={href}
                  target={target}
                >
                  {cta.label}
                </AnchorLink>
              ) : (
                <Button
                  className={styles.button}
                  size="medium"
                  visualizationType="primary"
                  // onClick={() => router.push('/registrati')}
                >
                  Registrati
                </Button>
              )}
            </div>
          )}
        </div>
      </Wrapper>
    );
  },
  function areEqual(prevProps, nextProps) {
    return (
      prevProps.title === nextProps.title &&
      isEqual(prevProps.images, nextProps.images) &&
      isEqual(prevProps.cta, nextProps.cta)
    );
  }
);
