import { RichText } from 'components/richText';
import styles from './SimpleInfoModalBody.module.scss';

export type SimpleInfoModalBodyProps = {
  text?: string;
  children?: React.ReactNode;
};

export const SimpleInfoModalBody = ({ text, children }: SimpleInfoModalBodyProps) => {
  return (
    <div className={styles.container}>
      {text && <RichText containerClassName={styles.paragraphWrapper}>{text}</RichText>}
      {children}
    </div>
  );
};
