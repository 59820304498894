import React, { memo, useRef } from 'react';
import { FreeMode, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/scss';
import { LazyGetTriggerType } from 'features/slotArchive/actions';
import { isEqual } from 'lodash';
import { useHideNavigationCarousel } from '../../../hooks/useHideNavigationCarousel';
import { breakpoint } from '../../../utility/constant';
import { CardTypes } from '../../cards/types';
import { SectionTitle, SectionTitleType } from '../../sectionTitle';
import { Wrapper } from '../../wrapper';
import { CardType, ConditionalCard } from '../components/conditionalCard/ConditionalCard';
import styles from './DefaultCarousel.module.scss';

export type DefaultCarouselProps<T extends CardTypes> = Omit<SectionTitleType, 'nopadding' | 'className'> & {
  typeOfItem: CardType;
  listItem: T[];
  triggerRefetch?: LazyGetTriggerType;
};

export const DefaultCarousel: React.FC<DefaultCarouselProps<CardTypes>> = memo(
  function DefaultCarousel({
    title,
    subtitle,
    url,
    typeOfItem,
    listItem,
    triggerRefetch,
  }: DefaultCarouselProps<CardTypes>) {
    const swiperRef = useRef<any>(null);
    const { isScrollable, swiperSlideRef } = useHideNavigationCarousel({ className: 'disabled', items: [...listItem] });

    return (
      <Wrapper noPaddingRight noPaddingLeft>
        {title && <SectionTitle title={title} subtitle={subtitle} url={url} />}
        <div className={styles.container}>
          <Swiper
            onInit={(core) => (swiperSlideRef.current = core.el)}
            spaceBetween={typeOfItem === 'app' ? 56 : 10}
            grabCursor
            slidesPerView={'auto'}
            slidesOffsetBefore={20}
            slidesOffsetAfter={20}
            modules={[Navigation, FreeMode, Mousewheel]}
            updateOnWindowResize
            watchOverflow
            freeMode={{
              enabled: true,
              minimumVelocity: 0.2,
            }}
            mousewheel={{ forceToAxis: true }}
            navigation={isScrollable}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            breakpoints={{
              [breakpoint.sm]: {
                slidesOffsetBefore: 32,
                slidesOffsetAfter: 32,
              },
              [breakpoint.lg]: {
                grabCursor: false,
                spaceBetween: typeOfItem === 'app' ? 56 : 24,
                slidesOffsetBefore: 40,
                slidesOffsetAfter: 40,
                simulateTouch: false,
                allowTouchMove: false,
              },
            }}
          >
            {listItem &&
              listItem.map((item, index) => {
                return (
                  <SwiperSlide key={item.id + '_' + index} className={typeOfItem ? styles[typeOfItem] : ''}>
                    <ConditionalCard type={typeOfItem} item={item} triggerRefetch={triggerRefetch} />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </Wrapper>
    );
  },
  function areEqual(prevProps, nextProps) {
    return (
      prevProps.title === nextProps.title &&
      prevProps.subtitle === nextProps.subtitle &&
      prevProps.url === nextProps.url &&
      prevProps.typeOfItem === nextProps.typeOfItem &&
      isEqual(prevProps.listItem, nextProps.listItem) &&
      prevProps.triggerRefetch === nextProps.triggerRefetch
    );
  }
);
