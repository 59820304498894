import { AnchorLink } from 'components/anchorLink';
import Image from 'next/image';
import styles from './FooterWarning.module.scss';
export type FooterWarningProps = {
  warningLogo: string;
  warningText: string;
  link?: {
    url: string;
    label: string;
  };
};

export const FooterWarning = ({ warningLogo, warningText, link }: FooterWarningProps) => {
  return (
    <div className={link ? styles.containerWithLink : styles.container}>
      {link && (
        <AnchorLink className={styles.link} href={link.url}>
          {link.label}
        </AnchorLink>
      )}
      <div className={styles.warningTextContainer}>
        <Image src={warningLogo} className={styles.logo} alt="Logo Snai" width={128} height={32} />
        <p className={styles.warning} dangerouslySetInnerHTML={{ __html: warningText }} />
      </div>
    </div>
  );
};
