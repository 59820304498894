import { ReactPortal } from 'components/reactPortal';
import { RootState } from 'lib/centralStore';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { Modal } from '../components/modal';
import { SimpleInfoModalBody } from './components/simpleInfoModalBody';

export type SimpleInfoModalProps = {
  isOpen?: boolean;
  title: string;
  text?: string;
  children?: React.ReactNode;
  modalType: 'primary' | 'secondary' | 'tertiary';
  onClose?: () => void;
};

export const DynamicSimpleInfoModal = dynamic(() =>
  import('components/modals/simpleInfoModal/SimpleInfoModal').then((mod) => mod.SimpleInfoModal)
);
export const SimpleInfoModal = ({ title, text, isOpen, children, modalType, onClose }: SimpleInfoModalProps) => {
  const { isOpenSimpleInfo } = useSelector((store: RootState) => store.modal);
  if (!isOpenSimpleInfo || !isOpen) return null;

  return (
    <ReactPortal wrapperId="simpleModal">
      <Modal label={title} modalType={modalType} modalCategory="simple-modal" onClose={onClose}>
        <SimpleInfoModalBody text={text}>{children}</SimpleInfoModalBody>
      </Modal>
    </ReactPortal>
  );
};
