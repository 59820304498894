// BANNER
export const BANNER = 'banner_on_page';
export const BANNER_UNIQUE = 'banner_unique_home';

// export const BANNER_PROMO = 'promo';
// STRIPE
export const STRIPE = 'stripe_scorrevole';

export const BENEFIT = 'benefit_block';
export const SECTION_TITLE = 'section_title_block';
export const SCOMMESSE = 'scommesse_block';
export const SLOT_CLASSIFICA_CONSOLE_GIOCHI = 'classifica_console_giochi_slot_list_homepage';
export const SLOT_DEFAULT_CONSOLE_GIOCHI = 'default_console_giochi_slot_list_homepage';

export const STRIPE_FROM_CMS = 'stripe_scorrevole_cms_block';
export const LE_NOSTRE_APP = 'le_nostre_app_block';
export const BONUS = 'bonus_list_block';
