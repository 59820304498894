import { Icon, IconsEnum } from 'components/Icons';

import { AnchorLink } from 'components/anchorLink';
import { ItemWithTranslation } from 'types/swagger';
import { memo } from 'react';
import styles from './ScommesseInEvidenceCardHeader.module.scss';
import { toSportUrlCase } from 'utility/functions';
import { useTranslation } from 'hooks/useLingUI';

export type SportInEvidenceCardHeaderProps = ItemWithTranslation<{ manifestazioneLabel: string }> & {
  children: React.ReactNode;
  disciplinaIcon: IconsEnum;
  urlManifestazione?: string;
};
export const ScommesseInEvidenceCardHeader = memo(function ScommesseInEvidenceCardHeader({
  children,
  disciplinaIcon,
  urlManifestazione,
  manifestazioneLabel,
  manifestazioneLabelTrKey,
}: SportInEvidenceCardHeaderProps) {
  const { t } = useTranslation();
  return (
    <div className={styles.headerContainer}>
      <div className={styles.wrapperManifestazioneLabel}>
        <Icon iconId={disciplinaIcon} color="var(--color-on-bg)" className={styles.iconDisciplina} />
        <AnchorLink href={toSportUrlCase(urlManifestazione)} className={styles.label}>
          {t(manifestazioneLabelTrKey, manifestazioneLabel)}
        </AnchorLink>
      </div>
      {children}
    </div>
  );
});
