import { SportsCompetitor, SportsLiveScore } from 'types/swagger';

export type Score = { score: string };

export const mergeCompetitorWithScore = (
  competitors: Array<SportsCompetitor>,
  liveScore?: SportsLiveScore | undefined
): Array<SportsCompetitor | Score> => {
  if (competitors.length > 1) {
    const [home, visitors] = competitors;
    const { firstCompetitor, secondCompetitor } = liveScore ?? {};
    const score: string =
      firstCompetitor && secondCompetitor ? `${firstCompetitor}-${secondCompetitor}`.replace(/:/gim, '-') : '-';
    return [home, { score } as Score, visitors];
  }
  return competitors;
};
