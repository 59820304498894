import { ElementWithQuote, ElementWithTime } from './types';

import { isTruthy } from 'utility/functions';

export const matchesOrario = (element: Partial<ElementWithTime>, orario: string) => {
  if (orario === 'default') return true;

  const flag = Reflect.get(element ?? {}, orario);

  return isTruthy(flag);
};

// we can assert only that:
// filtering by min all elements with quotaMax lower than filter.min must be excluded and
// filtering by max all elements with a quotaMin greater than filetr.max must be excluded
export const matchesQuote = (element: ElementWithQuote, min: number, max: number) => {
  const { quotaMin, quotaMax } = element ?? {};

  // return (quotaMin >= min) && (quotaMax <= max || quotaMax > 100);

  let ckMin = true;
  if (min > 1) {
    ckMin = quotaMax >= min && quotaMin <= min;
  }

  let ckMax = true;
  if (max < 100) {
    ckMax = quotaMin <= max && quotaMax >= max;
  }

  // console.log(`matchesQuote(${Reflect.get(element, 'descrizione')}, min:${min}, max:${max}, {quotaMin:${quotaMin}, quotaMax:${quotaMax}}) -> ckMin:${ckMin} + ckMax:${ckMax} => ${ckMax && ckMin}`)

  return ckMax && ckMin;
};
