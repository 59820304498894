import { GetStaticProps, InferGetStaticPropsType } from 'next';
import { createPaths, skipStaticPageGeneration } from 'utility/functions';

import { Layout } from 'components/Layout';
import { appInsight } from 'components/appInsight';
import { BonusBar } from 'components/bonusBar';
import { FooterWarning } from 'components/footerWarning';
import PageContent from 'components/page-content/generic-page/PageContent';
import PageContentHomepage from 'components/page-content/homepage/PageContent';
import { Seo } from 'components/seo';
import { mergeTagsFromSeoData } from 'components/seo/utils';
import { AmbienteContext } from 'context/Ambiente';
import { ssrGET } from 'lib/auth';
import { getAllLabelsPersonalAreas } from 'lib/datoCms/queries/getAllLabelsPersonalAreas';
import { getAllStaticPage } from 'lib/datoCms/queries/getAllStaticPage';
import { getHomepage } from 'lib/datoCms/queries/getHomepage';
import { getStaticPage } from 'lib/datoCms/queries/getStaticPage';
import { DatoCMSResponse } from 'lib/types/dato-cms.response';
import React from 'react';
import { getDatoCMSData } from 'sites/snai/lib/datoCms/queries/getDatoCMSData';
import { PagesPathArray } from 'types/pages';
import { ISR_REVALIDATE } from 'utility/constant';

const StaticPage = ({
  site,
  header,
  footer,
  seoData,
  needHelp,
  bonusBar,
  isHomePage,
  cmsContent,
  disclaimer,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { seoMetaTags, canonical, ...pageData } = cmsContent ?? {};

  return isHomePage ? (
    <React.Fragment>
      {disclaimer && <FooterWarning warningLogo={footer.logoAdm18.url} {...disclaimer} />}
      {bonusBar && <BonusBar {...bonusBar} />}
      <Layout header={header} needHelp={needHelp} footer={footer}>
        <Seo siteSeo={site} metaTags={seoMetaTags} canonicalUrl={canonical} />
        <AmbienteContext.Provider value={{ pathScommessa: ['sport', 'scommessa'], ambiente: 'sport' }}>
          <PageContentHomepage homePage={pageData} seoData={seoData} />
        </AmbienteContext.Provider>
      </Layout>
    </React.Fragment>
  ) : (
    <Layout header={header} needHelp={needHelp} footer={footer}>
      <Seo siteSeo={site} metaTags={seoMetaTags} canonicalUrl={canonical} />
      <PageContent title={pageData?.title ?? seoData?.titoloDiRiferimento} staticPage={pageData} seoData={seoData} />
    </Layout>
  );
};

export async function getStaticPaths({ locales }) {
  console.log('getStaticPaths : GENERICHE');

  ssrGET();
  locales?.forEach(async (locale) => {
    await getAllLabelsPersonalAreas(locale);
    getDatoCMSData('common', locale);
  });

  if (skipStaticPageGeneration()) {
    return {
      paths: [{ params: { slug: ['home'] } }],
      fallback: 'blocking',
    };
  }
  const pathsArray: PagesPathArray[] = [];

  locales?.forEach((language) => {
    pathsArray.push({ params: { slug: [] }, locale: language });
  });
  const { allStaticPages: allPages } = await getAllStaticPage();
  const slugs = createPaths(allPages);

  slugs.forEach((slug) => {
    locales?.forEach((language) => {
      pathsArray.push({ params: { slug: slug.slice(1).split('/') }, locale: language });
    });
  });

  const paths = pathsArray.filter((path) => {
    const array = ['faq', 'regolamenti', 'cookie-policy', 'privacy-policy', 'apri-un-negozio', 'regole-del-gioco'];
    if (array.includes(path.params.slug[0])) {
      return false;
    }
    return true;
  });
  return {
    paths: paths,
    fallback: 'blocking',
  };
}

export const getStaticProps: GetStaticProps = async ({ params, locale }) => {
  console.log(`## sport.disciplina.getStaticProps GENERICHE`);

  const options = Array.isArray(params?.slug) ? params.slug : [params?.slug];
  const slug =
    options
      .filter((x) => !!x)
      .map((x) => `${x}`.toLowerCase())
      .pop() ?? '';

  if (`${slug}`.toLowerCase().endsWith('.js')) {
    // if catch-all page interceps .js document request
    // it means the js url is misconfigured
    // redirect to fake file to avoid error "<" is not a valid json
    console.log(`${options.join('/')} is not a local file`);
    return {
      redirect: {
        permanent: false,
        destination: slug,
      },
    };
  }

  try {
    const promises: Array<Promise<any>> = [];

    let isHomePage = ['', 'home', 'homePage', 'landing', 'landingPage'].includes(slug);
    console.log(`slug: ${slug}, isHomePage: ${isHomePage}`);

    const prCmsData = getDatoCMSData('common', locale);
    promises.push(prCmsData);

    if (isHomePage) {
      const prHomepage = getHomepage(locale);
      promises.push(prHomepage);
    } else {
      const prStaticPage = getStaticPage(slug, locale);
      promises.push(prStaticPage);
    }

    const [cmsData, pageData] = await Promise.all(promises);
    const { site, header, needHelp, footer, allLabels } = cmsData as DatoCMSResponse;
    const { tabsWithAccordionModel: seoData } = pageData ?? {};

    const cmsContent = mergeTagsFromSeoData({
      seoData,
      cmsData: isHomePage ? pageData : pageData.allStaticPages,
      fieldName: isHomePage ? 'homePage' : '0',
    });

    return Promise.resolve({
      props: {
        site,
        header,
        footer,
        seoData: seoData ?? null,
        needHelp,
        labels: allLabels,
        isHomePage,
        cmsContent: (isHomePage ? cmsContent?.homePage : cmsContent?.[0]) ?? null,
      },
      revalidate: ISR_REVALIDATE * 10,
    });
  } catch (exception) {
    appInsight.trackException({
      exception,
      id: slug ?? 'catch all page',
    });

    return Promise.resolve({ notFound: true });
  }
};

export default StaticPage;
