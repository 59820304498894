import React, { Fragment } from 'react';
import { RenderHomepageComponentByModel } from './RenderHomepageComponentByModel';
export type HomepageVisualComposerProps = {
  components: HomepageVisualComposer<any>[] | undefined;
};
export type HomepageVisualComposer<T> = {
  id: string;
  modelApiKey: string;
  title?: string;
  subTitle?: string;
  link?: string;
  componentData: T;
};

export const HomepageVisualComposer = ({ components }: HomepageVisualComposerProps) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment>
      {components &&
        components?.map((component: HomepageVisualComposer<any>, index: number) => {
          /** Using an external HOOK for sorting different components to render */
          if (component?.modelApiKey) {
            return <Fragment key={component.id + index}>{RenderHomepageComponentByModel(component)}</Fragment>;
          }
          return <Fragment key={Math.random() * 100} />;
        })}
    </React.Fragment>
  );
};
