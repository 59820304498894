import { FreeMode, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { memo, useRef } from 'react';

import { AnchorLink } from 'components/anchorLink';
import { ScommesseInEvidenzaCard } from 'components/cards/scommesseInEvidenceCard';
import { Wrapper } from 'components/wrapper';
import { breakpoint } from 'utility/constant';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import styles from './ScommesseDaNonPerdereCarousel.module.scss';
import { useFilteredAvvenimentoByKey } from 'features/sport/hooks';
import { useGetLabelByKey } from 'hooks/useLingUI';
import { useHideNavigationCarousel } from 'hooks';

export type ScommesseDaNonPerdereCarouselProps = {
  title: string;
  subtitle?: string;
  className: string;
  isTopTemplate: boolean;
  inEvidenzaKeys: string[];
};

export const ScommesseDaNonPerdereCarousel = memo(
  function ScommesseDaNonPerdereCarousel({
    title,
    subtitle,
    className,
    inEvidenzaKeys,
    isTopTemplate,
  }: ScommesseDaNonPerdereCarouselProps) {
    const swiperRef = useRef<any>(null);
    const labelTutteLeScommesse = useGetLabelByKey('label-all-scommesse-sportive');

    const keys = useFilteredAvvenimentoByKey(inEvidenzaKeys);
    const { isScrollable, swiperSlideRef } = useHideNavigationCarousel({ className: 'disabled', items: keys });

    return (
      <Wrapper noPaddingLeft noPaddingRight noPaddingBottom>
        {title && <h2 className={classNames(styles.title, styles.paddingLeft)}>{title}</h2>}
        {subtitle && <p className={classNames(styles.subtitle, styles.paddingLeft)}>{subtitle}</p>}
        <div className={classNames(styles.container, styles[className])}>
          <Swiper
            onInit={(core) => (swiperSlideRef!.current = core.el)}
            spaceBetween={10}
            grabCursor
            slidesPerView={'auto'}
            slidesOffsetBefore={20}
            slidesOffsetAfter={20}
            modules={[Navigation, FreeMode, Mousewheel]}
            updateOnWindowResize
            watchOverflow
            freeMode={{
              enabled: true,
              minimumVelocity: 0.2,
            }}
            mousewheel={{ forceToAxis: true }}
            navigation={isScrollable}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            breakpoints={{
              [breakpoint.sm]: {
                slidesOffsetBefore: +`${40}`,
                slidesOffsetAfter: 32,
              },
              [breakpoint.lg]: {
                grabCursor: false,
                spaceBetween: 24,
                slidesOffsetBefore: +`${40}`,
                slidesOffsetAfter: 40,
                simulateTouch: false,
                allowTouchMove: false,
              },
            }}
          >
            {keys?.map((key) => (
              <SwiperSlide key={key}>
                <ScommesseInEvidenzaCard scommessaInEvidenzaKey={key} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={styles.contentRight}>
          <AnchorLink href="/sport" className={styles.link}>
            {labelTutteLeScommesse}
          </AnchorLink>
        </div>
      </Wrapper>
    );
  },
  function areEqual(prevProps, nextProps) {
    return (
      prevProps.title === nextProps.title &&
      prevProps.subtitle === nextProps.subtitle &&
      prevProps.className === nextProps.className &&
      isEqual(prevProps.inEvidenzaKeys, nextProps.inEvidenzaKeys) &&
      prevProps.isTopTemplate === nextProps.isTopTemplate
    );
  }
);
