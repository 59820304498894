import { StripeScorrevoleContent } from 'components/stripeScorrevole/stripeScorrevoleContent';
import { Wrapper } from 'components/wrapper';
import { useGetHomepageStripeValuesQuery } from 'features/api/externalApiSlice';
import { StripeItem } from 'lib/datoCms/queries/getHomepage';
import { memo, useMemo } from 'react';
import Marquee from 'react-fast-marquee';
import styles from './StripeScorrevoleLabels.module.scss';

export type StripeScorrevoleLabelsProps = {
  data: StripeItem[];
};

export type StripeItemWithValue = StripeItem & {
  value: string;
};
export const StripeScorrevoleLabels = memo(function StripeScorrevoleLabels({ data }: StripeScorrevoleLabelsProps) {
  const { data: stripeValues } = useGetHomepageStripeValuesQuery(undefined, {
    pollingInterval: 5000,
  });

  const dataValues = useMemo(() => {
    let withValues: StripeItemWithValue[] = [];
    if (stripeValues && data) {
      withValues = data.map((item) => {
        return {
          ...item,
          value: stripeValues[item.identificativo],
        };
      });
    }
    return withValues;
  }, [stripeValues, data]);

  return (
    <Wrapper noPaddingRight noPaddingLeft bgColorTransparent>
      <Marquee play gradient={false} direction="left" speed={105} loop={0} className={styles.marquee}>
        {dataValues.map((item, i) => (
          <StripeScorrevoleContent
            key={i}
            label={item.title}
            classNameStripe={styles.stripe}
            classNameTitle={styles.stripeTitle}
            classNameContent={styles.stripeContent}
            value={item.value}
          />
        ))}
      </Marquee>
    </Wrapper>
  );
});
