import {
  FaseOptions,
  GoalsOptions,
  GolNoGolOptions,
  LiveFiltersType,
  StatoMatchOptions,
  enFase,
  enFiltersKind,
  sequence,
} from './types';
import { hasValue, isMatch } from 'utility/functions';

import { useGetLabelsByKeys } from 'hooks/useLingUI';

const clean = (input: string): string => {
  return input
    .replace(/\^|\$|\(|\)|\[|\]|\*|\\s||\\d/gim, '')
    .replace(/\.| /gim, '_')
    .toLowerCase();
};

const keyList: Array<string> = [
  'live-filters-phase',
  'live-filters-timing',
  'live-filters-sum-goal',
  'live-filters-diff-goals',
  'live-filters-goal-nogoal',
  'live-filters-status-match',
  ...FaseOptions.map((value) => `live-filters-phase-${clean(value)}`),
  ...GoalsOptions.map((value) => `live-filters-goals-${value}`),
  ...GolNoGolOptions.map((value) => `live-filters-goal-nogoal-${clean(value)}`),
  ...StatoMatchOptions.map((value) => `live-filters-status-match-${clean(value)}`),
];

export interface LiveFiltersHookType {
  filters: LiveFiltersType;
  sequence: Array<string>;
}

export const useLiveFilters = (): LiveFiltersHookType => {
  const dictionary: Record<string, string> = {};
  useGetLabelsByKeys(keyList).forEach((x, index) => {
    dictionary[keyList[index]] = x;
  });

  const getLabel = (key: string, fallback?: string | number): string => {
    const wKey = clean(key);
    let result = dictionary[wKey];
    if (hasValue(fallback) && isMatch(result, `${wKey}$`)) {
      return `${fallback}`;
    }
    return result;
  };

  const filters: LiveFiltersType = {
    minutaggio: {
      type: enFiltersKind.Minutaggio,
      label: getLabel('live-filters-timing'),
      values: [
        { from: { value: 1, label: '1' }, to: { value: 15, label: '15' } },
        { from: { value: 16, label: '16' }, to: { value: 30, label: '30' } },
        { from: { value: 31, label: '31' }, to: { value: 45, label: '45' } },
        {
          from: { value: 40, label: '40' },
          to: { value: enFase.Breack, label: getLabel(`live-filters-phase-${clean(enFase.Breack)}`) },
        },
        { from: { value: 45, label: '45' }, to: { value: 60, label: '60' } },
        { from: { value: 61, label: '61' }, to: { value: 75, label: '75' } },
        { from: { value: 76, label: '76' }, to: { value: 90, label: '90' } },
        {
          from: { value: 85, label: '85' },
          to: { value: enFase.End, label: getLabel(`live-filters-phase-${clean(enFase.End)}`) },
        },
      ],
    },
    sommaGol: {
      type: enFiltersKind.SommaGol,
      label: getLabel('live-filters-sum-goal'),
      values: GoalsOptions.map((value) => ({ value, label: getLabel(`live-filters-goals-${value}`, value) })),
    },
    diffGol: {
      type: enFiltersKind.DiffGol,
      label: getLabel('live-filters-diff-goals'),
      values: GoalsOptions.map((value) => ({ value, label: getLabel(`live-filters-goals-${value}`, value) })),
    },
    fase: {
      type: enFiltersKind.Fase,
      label: getLabel('live-filters-phase'),
      values: FaseOptions.filter((x) => x !== enFase.End).map((value) => ({
        value,
        label: getLabel(`live-filters-phase-${clean(value)}`),
      })),
    },
    statoMatch: {
      type: enFiltersKind.StatoMatch,
      label: getLabel('live-filters-status-match'),
      values: StatoMatchOptions.map((value) => ({
        value,
        label: getLabel(`live-filters-status-match-${clean(value)}`),
      })),
    },
    golNoGol: {
      type: enFiltersKind.GolNoGol,
      label: getLabel('live-filters-goal-nogoal'),
      values: GolNoGolOptions.map((value) => ({ value, label: getLabel(`live-filters-goal-nogoal-${clean(value)}`) })),
    },
  };
  return { filters, sequence };
};
