import { Icon, IconsEnum } from 'components/Icons';
import { RootState, useAppDispatch } from 'lib/centralStore';
import React, { useEffect, useMemo, useState } from 'react';
import { PathName, getRouteByKey } from 'utility/routes';

import { AnchorLink } from 'components/anchorLink';
import { SimpleInfoModal } from 'components/modals/simpleInfoModal';
import { setIsOpenSimpleInfoValue } from 'features/modal/modalSlice';
import { useGetLabelByKey } from 'hooks/useLingUI';
import { ImageProps } from 'next/image';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { PROVENIENCE_QUERY_PARAM, isAustrianSite, isHappybetSite } from 'utility/constant';
import styles from './MyBonusCarouselItem.module.scss';

export type MyBonusCarouselItemProps = {
  id: string;
  typeOfBonus: string;
  text: string;
  subtitle: string;
  bgImage: ImageProps;
  informationTitle: string;
  informationText: string;
  promoCode: string;
};

export const MyBonusCarouselItem = ({
  id,
  typeOfBonus,
  text,
  subtitle,
  bgImage,
  informationTitle,
  informationText,
  promoCode,
}: MyBonusCarouselItemProps) => {
  const dispatch = useAppDispatch();
  const { isOpenSimpleInfo } = useSelector((store: RootState) => store.modal);
  const [currentBonus, setCurrentBonus] = useState<string | null>(null);
  const router = useRouter();
  const labelRegister = useGetLabelByKey('register');

  const slugProvenienza = useMemo(() => {
    const splittedSlug = router.asPath.split('/');
    const slug = splittedSlug.length > 1 ? splittedSlug[1] : splittedSlug[0];
    return slug || 'homepage';
  }, [router.asPath]);

  useEffect(() => {
    if (!isOpenSimpleInfo) {
      setCurrentBonus('');
    }
  }, [isOpenSimpleInfo]);

  const handleClickInfo = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    event.stopPropagation();
    setCurrentBonus(id);
    if (event.currentTarget.id === id) {
      dispatch(
        setIsOpenSimpleInfoValue({
          condition: event.currentTarget.id === id,
          setModal: true,
        })
      );
    }
  };

  const registrationUrl = useMemo(() => getRouteByKey(PathName.registrationUrl), []);

  return (
    <React.Fragment>
      <article
        id={id}
        className={`${styles.container}`}
        style={{
          backgroundImage: `${bgImage && 'url(' + bgImage.src + ')'}`,
        }}
      >
        <span className={styles.type}>Bonus {typeOfBonus}</span>
        <div className={styles.textContainer}>
          <button id={id} type="button" className={styles.button} onClick={(e) => handleClickInfo(e, id)}>
            <Icon className={styles.icon} iconId={IconsEnum.INFO} color="var(--color-neutral-01)" />
          </button>
          <p className={styles.text}>{text}</p>
          <p className={styles.subtitle}>{subtitle}</p>
          {!isAustrianSite && (
            <div className={styles.linkContainer}>
              <AnchorLink
                className={styles.link}
                href={`${registrationUrl}?${PROVENIENCE_QUERY_PARAM}=${`${
                  isHappybetSite ? 'newhpybet_' : 'newsnai_'
                }${slugProvenienza}&COD_PROM=${promoCode}`}`}
              >
                {labelRegister}
              </AnchorLink>
            </div>
          )}
        </div>
      </article>
      {isOpenSimpleInfo && id === currentBonus && (
        <SimpleInfoModal
          isOpen={isOpenSimpleInfo}
          title={informationTitle}
          text={informationText}
          modalType={'secondary'}
        />
      )}
    </React.Fragment>
  );
};
