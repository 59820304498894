import { GamesSkillGameDto, SportsCacheTemplateDisciplinaDto } from 'types/swagger';
import { RootState, useTypedSelector } from 'lib/centralStore';
import { useEffect, useMemo } from 'react';
import { useGetScommessePreMatchQuery, useGetTemplateDisciplinaBySlugQuery } from 'features/api/sportApiSlice';

import { HomepageQuery } from 'lib/datoCms/queries/getHomepage';
import { HomepageVisualComposer } from 'components/homepageVisualComposer';
import { ScommessaResponse } from 'lib/api/sport/sportScommesseBySlugResponse';
import { Skeleton } from 'components/skeleton';
import { TabsWithAccordion } from 'components/tabsWithAccordion';
import { TabsWithAccordionType } from 'lib/datoCms/queries/getSeoData';
import { createPageComposition } from 'components/homepageVisualComposer/utils/utils';
import { isSnaiSite } from 'utility/constant';
import { setCookie } from 'lib/persist/cookie';
import { useGetSlotByListNameQuery } from 'features/api/apiSlice';
import { useRouter } from 'next/router';

type Props = {
  seoData?: TabsWithAccordionType;
  homePage: HomepageQuery['homePage'];
};

const PageContent = ({ homePage, seoData }: Props) => {
  useGetScommessePreMatchQuery('sport/homepage', { refetchOnMountOrArgChange: true });
  useGetTemplateDisciplinaBySlugQuery('sport/homepage', { refetchOnMountOrArgChange: true });
  const router = useRouter();

  const sportTemplateResponseData = useTypedSelector((state: RootState) => state.sport.templateDisciplina);

  const { currentData: slotCatalogResposeApi, isLoading: isLoadingSlotCatalog } = useGetSlotByListNameQuery(
    {
      listName: 'listTop10',
      pageNumber: 1,
      pageSize: 10,
    },
    { skip: !isSnaiSite }
  );

  useEffect(() => {
    if (router.query['btag']) setCookie('btag', router.query['btag'] as string, 7);
  }, [router]);

  const newSlotCataloResponseApi = useMemo(() => {
    if (slotCatalogResposeApi) {
      const { games, ...rest } = slotCatalogResposeApi;
      const newList: Array<GamesSkillGameDto> = [...games!];
      return {
        games: newList,
        ...rest,
      };
    } else return { games: [], totalCount: 0, totalPages: 0 };
  }, [slotCatalogResposeApi]);

  const newVisualComposer = useMemo(() => {
    if (!homePage?.visualComposer) return undefined;

    return (
      homePage.visualComposer &&
      newSlotCataloResponseApi &&
      createPageComposition<{
        template: SportsCacheTemplateDisciplinaDto;
        scommesseResponse: ScommessaResponse;
      }>({
        visualComposer: homePage.visualComposer,
        catalog: {
          template: sportTemplateResponseData,
          ...newSlotCataloResponseApi,
        },
        slug: '',
      }).map((e) => e!)
    );
  }, [sportTemplateResponseData, newSlotCataloResponseApi, homePage]);

  if (isLoadingSlotCatalog) {
    return <Skeleton />;
  }

  return (
    <div>
      {newVisualComposer && <HomepageVisualComposer components={newVisualComposer} />}
      <TabsWithAccordion tabs={seoData?.tabs ?? []} />
    </div>
  );
};

export default PageContent;
