import { IconsEnum } from 'components/Icons';
import { Wrapper } from 'components/wrapper';
import { useTheme } from 'next-themes';
import { memo } from 'react';
import styles from './Benefit.module.scss';
import { BenefitBox } from './benefitBox';

export type BenefitProps = {
  title: string;
  subtitle: string;
  labelAdm: string;
  labelDashboard: string;
  labelFastWithdrawalsDeposits: string;
  labelBonus: string;
};

export const Benefit = memo(function Benefit({
  title,
  subtitle,
  labelAdm,
  labelDashboard,
  labelFastWithdrawalsDeposits,
  labelBonus,
}: BenefitProps) {
  const { theme } = useTheme();
  const isDarkMode = () => theme === 'dark';

  return (
    <Wrapper noPaddingTop noPaddingBottom noPaddingLeft noPaddingRight>
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <div className={styles.title}>{title}</div>
          <p className={styles.subtitle}>{subtitle}</p>
        </div>
        <BenefitBox
          isMainBox
          className={styles.mainBox}
          icon={isDarkMode() ? IconsEnum.ADM_WHITE_HORIZONTAL : IconsEnum.ADM_COLORFUL_HORIZONTAL}
          text={labelAdm}
        />
        <div className={styles.listBoxWrapper}>
          <div className={styles.listBoxContainer}>
            <BenefitBox
              className={styles.box}
              icon={IconsEnum.DEPOSIT}
              iconColor="var(--color-accent)"
              text={labelFastWithdrawalsDeposits}
            />
            <BenefitBox
              className={styles.box}
              icon={IconsEnum.GIFT}
              iconColor="var(--color-accent)"
              text={labelBonus}
            />
            <BenefitBox
              className={styles.box}
              icon={IconsEnum.DASHBOARD_UPDATED}
              iconColor="var(--color-accent)"
              text={labelDashboard}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
});
