import styles from './ScommesseInEvidenceCardFooter.module.scss';
export type ScommesseInEvidenceCardFooterProps = {
  children: React.ReactNode;
};
export type Evento = {
  descEvento: string;
  quota: number;
  isIncreasing: boolean;
  isDecreasing: boolean;
};
export const ScommesseInEvidenceCardFooter = ({ children }: ScommesseInEvidenceCardFooterProps) => {
  return <div className={styles.container}>{children}</div>;
};
