import { Icon, IconsEnum } from 'components/Icons';
import { PathName, getRouteByKey } from 'utility/routes';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { AnchorLink } from 'components/anchorLink';
import { Button } from 'components/button';
import { isClientSide } from 'utility/functions';
import styles from './BonusBar.module.scss';
import { useRouter } from 'next/router';

export type BonusBarProps = {
  titleDesktop: string;
  titleMobile: string;
  subtitle: string;
  cta?: {
    url: string;
    label: string;
  };
};

export const BonusBar = ({ titleDesktop, titleMobile, subtitle, cta }: BonusBarProps) => {
  const router = useRouter();
  const [y, setY] = useState(0);
  const [isScroll, setIsScroll] = useState(false);

  const onScrollHandler = useCallback(() => {
    if (isClientSide()) {
      if (y > window.scrollY) {
        setIsScroll(false);
      } else if (y < window.scrollY) {
        setIsScroll(true);
      }
      setY(window.scrollY);
    }
  }, [y]);

  useEffect(() => {
    if (isClientSide()) {
      setY(window.scrollY);
      window.addEventListener('scroll', onScrollHandler, { passive: true });
    }

    return () => {
      if (isClientSide()) {
        window.removeEventListener('scroll', onScrollHandler);
      }
    };
  }, [onScrollHandler]);

  const registrationUrl = useMemo(() => getRouteByKey(PathName.registrationUrl), []);

  return (
    <div className={`${styles.container} ${isScroll ? styles.scrollDown : ''}`}>
      <div className={styles.textContainer}>
        <div>
          <Icon iconId={IconsEnum.SNAI_PICTO} className={styles.icon} color="var(--color-surface-01)" />
        </div>
        <div>
          <p className={styles.titleDesktop}>{titleDesktop}</p>
          <p className={styles.titleMobile}>{titleMobile}</p>
          <p className={styles.subtitle}>{subtitle}</p>
        </div>
      </div>
      <div className={styles.linkContainer}>
        {cta?.url && (
          <AnchorLink size="medium" target="_self" href={cta.url} className={styles.cta}>
            {cta.label}
          </AnchorLink>
        )}
        <Button
          visualizationType="tertiary"
          size="medium"
          className={styles.boxBtn}
          onClick={() => router.push(registrationUrl)}
        >
          Registrati
        </Button>
      </div>
    </div>
  );
};
