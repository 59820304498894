import { FreeMode, Mousewheel, Navigation } from 'swiper';
import styles from './MyBonusCarousel.module.scss';

import { MyBonusCarouselItem } from 'components/myBonusCarouselItem';
import { Wrapper } from 'components/wrapper';
import { useHideNavigationCarousel } from 'hooks';
import { isEqual } from 'lodash';
import { ImageProps } from 'next/image';
import { memo, useRef } from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { breakpoint } from 'utility/constant';

export type MyBonusCarouselProps = {
  title: string;
  subtitle?: string;
  listItem: {
    id: string;
    typeOfBonus: string;
    text: string;
    subtitle: string;
    bgImage: ImageProps;
    promoCode: string;
    informationTitle: string;
    informationText: string;
  }[];
} & SwiperProps;

export const MyBonusCarousel = memo(
  function MyBonusCarousel(props: MyBonusCarouselProps) {
    const swiperRef = useRef<any>(null);
    let { isScrollable, swiperSlideRef } = useHideNavigationCarousel({
      className: 'disabled',
      items: [...props.listItem],
    });

    return (
      <Wrapper noPaddingLeft noPaddingRight bgColorTransparent className={'myBonusBackgroundElement'}>
        {props.title && <h2 className={`${styles.title} ${styles.paddingLeft}`}>{props.title}</h2>}
        {props.subtitle && <p className={`${styles.subtitle} ${styles.paddingLeft}`}>{props.subtitle}</p>}
        <div className={`${styles.container} ${styles.myBonusContainer}`}>
          <Swiper
            onInit={(core) => (swiperSlideRef!.current = core.el)}
            spaceBetween={24}
            grabCursor
            slidesPerView={'auto'}
            slidesOffsetBefore={20}
            slidesOffsetAfter={20}
            modules={[Navigation, FreeMode, Mousewheel]}
            updateOnWindowResize
            watchOverflow
            freeMode={{
              enabled: true,
              minimumVelocity: 0.2,
            }}
            mousewheel={{ forceToAxis: true }}
            navigation={isScrollable}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            breakpoints={{
              [breakpoint.sm]: {
                slidesOffsetBefore: +`${40}`,
                slidesOffsetAfter: 32,
              },
              [breakpoint.lg]: {
                grabCursor: false,
                spaceBetween: 64,
                slidesOffsetBefore: +`${40}`,
                slidesOffsetAfter: 40,
                simulateTouch: false,
                allowTouchMove: false,
              },
            }}
          >
            {props.listItem &&
              props.listItem.map((item, index: number) => (
                <SwiperSlide key={item + '-' + index}>
                  <MyBonusCarouselItem
                    id={item.id}
                    typeOfBonus={item.typeOfBonus}
                    text={item.text}
                    subtitle={item.subtitle}
                    bgImage={item.bgImage}
                    informationTitle={item.informationTitle}
                    informationText={item.informationText}
                    promoCode={item.promoCode}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </Wrapper>
    );
  },
  function areEqual(prevProps: MyBonusCarouselProps, nextProps: MyBonusCarouselProps) {
    return (
      prevProps.title === nextProps.title &&
      prevProps.subtitle === nextProps.subtitle &&
      isEqual(prevProps.listItem, nextProps.listItem)
    );
  }
);
