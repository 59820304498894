import { Icon, IconsEnum } from 'components/Icons';
import { useCartToggleHandler, useIsInCart } from 'features/carrello/hooks';

import { memo } from 'react';
import styles from './ScommesseEsitoTipoTop.module.scss';
import { useEsito } from 'features/sport/hooks';
import { useQuotaVariation } from 'hooks';
import { useRouter } from 'next/router';
import { useTheme } from 'next-themes';
import { useTranslation } from 'hooks/useLingUI';

export type ScommesseEsitoTipoTopProps = {
  keyEsito?: string;
  noAction?: boolean;
  classNameButton?: string;
  classNameContainer?: string;
  gtagAction?: () => void;
};

export const ScommesseEsitoTipoTop = memo(function ScommesseEsitoTipoTop({
  keyEsito,
  noAction,
  classNameButton,
  classNameContainer,
  gtagAction,
}: ScommesseEsitoTipoTopProps) {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const isDarkMode = () => theme === 'dark';
  const isActiveInCart = useIsInCart(keyEsito);
  const maybeEsito = useEsito(keyEsito);
  const onCartToggleHandler = useCartToggleHandler(keyEsito);
  const { quota, isActive: isEsitoActive, descrizione, descrizioneTrKey } = maybeEsito ?? {};
  const pathname = useRouter().pathname;
  const [isArrowVisible, isIncreasing, isDecreasing] = useQuotaVariation(maybeEsito);
  if (!maybeEsito) {
    return null;
  }

  return (
    <div className={`${styles.container} ${classNameContainer ? classNameContainer : ''}`}>
      <span className={styles.label}>{t(descrizioneTrKey, descrizione)}</span>
      <button
        disabled={!isEsitoActive || noAction || !pathname.includes('/sport')}
        className={`${styles.buttonQuota} ${classNameButton ? classNameButton : ''} ${
          isActiveInCart ? styles.active : ''
        } ${noAction ? styles.noAction : ''}`}
        onClick={() => {
          gtagAction && gtagAction();
          onCartToggleHandler();
        }}
      >
        {isIncreasing && isArrowVisible && (
          <Icon
            key={`${isIncreasing}-${isArrowVisible}`}
            iconId={isDarkMode() ? IconsEnum.ARROW_INCREASE : IconsEnum.ARROW_INCREASE_LIGHT}
            className={`${styles.icon} ${styles.increasing}`}
          />
        )}
        {isEsitoActive && quota !== 0 ? (quota! < 10 ? quota!.toFixed(2) : quota) : '-'}
        {isDecreasing && isArrowVisible && (
          <Icon
            key={`${isDecreasing}-${isArrowVisible}`}
            iconId={isDarkMode() ? IconsEnum.ARROW_DECREASE : IconsEnum.ARROW_DECREASE_LIGHT}
            className={`${styles.icon} ${styles.decreasing}`}
          />
        )}
      </button>
    </div>
  );
});
