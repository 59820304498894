import React from 'react';

export type StripeScorrevoleContentProps = {
  classNameStripe: string;
  classNameTitle: string;
  classNameContent: string;
  label: string;
  value: string;
  children?: React.ReactNode;
};

export const StripeScorrevoleContent: React.FC<StripeScorrevoleContentProps> = ({
  ...props
}: StripeScorrevoleContentProps) => {
  return (
    <div className={props.classNameStripe}>
      <p className={props.classNameTitle}>
        {props.label}
        <span className={props.classNameContent}>{props.value}</span>
      </p>
      {props.children && props.children}
    </div>
  );
};
