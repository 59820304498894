import styles from './SkeletonRow.module.scss';

export type SkeletonRowProps = {
  hasTitle?: boolean;
};

export const SkeletonRow = ({ hasTitle }: SkeletonRowProps) => {
  return (
    <div className={styles.container}>
      {hasTitle && (
        <div className={styles.titleContainer}>
          <div className={styles.title} />
          <div className={styles.subtitle} />
        </div>
      )}
      <div className={styles.cardContainer}>
        {Array(8)
          .fill('')
          .map((_, index) => (
            <article className={styles.card} key={`row_${index}_${Math.random().toString(36).slice(2, 7)}`}>
              <div className={styles.cardImage} />
              <div className={styles.cardTitle} />
              <div className={styles.cardSubtitle} />
            </article>
          ))}
      </div>
    </div>
  );
};
