import { SkeletonRow } from './components/skeletonRow';
import styles from './Skeleton.module.scss';
export type SkeletonProps = {
  hasTitle?: boolean;
  rows?: number;
  hasPadding?: boolean;
};

export const Skeleton = ({ hasTitle, rows, hasPadding }: SkeletonProps) => {
  return (
    <div className={`${styles.container} ${hasPadding ? styles.paddingContainer : ''}`}>
      {Array(rows ?? 1)
        .fill('')
        .map((_, index) => (
          <SkeletonRow
            hasTitle={hasTitle}
            key={`skeleton_container_${index}_${Math.random().toString(36).slice(2, 7)}`}
          />
        ))}
    </div>
  );
};
