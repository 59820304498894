import { Icon, IconsEnum } from 'components/Icons';
import { RichText } from 'components/richText';
import styles from './BenefitBox.module.scss';

export type BenefitBoxProps = {
  icon: IconsEnum;
  iconColor?: string;
  text: string;
  className?: string;
  isMainBox?: boolean;
};

export const BenefitBox = ({ icon, iconColor, text, className, isMainBox }: BenefitBoxProps) => {
  return (
    <div className={`${styles.container} ${className ? className : ''} ${isMainBox ? styles.mainBox : ''}`}>
      <Icon iconId={icon} className={styles.icon} color={iconColor} />

      <RichText containerTag="span" containerClassName={styles.text}>
        {text}
      </RichText>
    </div>
  );
};
