import { InfoAmbiente, useAmbienteContext } from 'context/Ambiente';
import { selectIsActiveIppica, selectIsActiveVirtual } from 'features/configuration/selectors';
import { selectBonusGold, selectSaldo, selectUpdateWalletTimestamp } from 'features/dashboard/selectors';
import { enComparer, useScreenWidth } from 'hooks/useScreenWidth';
import { useAppDispatch, useTypedSelector } from 'lib/centralStore';
import { useCallback, useEffect, useMemo } from 'react';
import { breakpoint, isSnaiSite } from 'utility/constant';
import { addToCartByKey, removeFromCartByKey, updateTicket } from './actions';
import {
  selectIsSistemaByTicket,
  selectPuntataSingolaMultiplaByTicketType,
  selectSliceTicketByType,
  selectTipo,
  selectTotalePuntataScommesseSistemaByTicket,
} from './carrelloSelectors';

import addMinutes from 'date-fns/addMinutes';
import { useLazyGetWalletQuery } from 'features/api/apiSlice';
import { useLazyGetCartBonusQuery } from 'features/api/sportApiSlice';
import { useLazyGetSessionDataQuery } from 'features/api/userSlice';
import { useLazyGetVirtualBonusQuery } from 'features/api/virtualApiSlice';
import { bonusConfigurationAdded as ippicaBonusConfiguration } from 'features/ippica/components/ippicaTicket/ippicaTicketSlice';
import { bonusConfigurationAdded } from 'features/sport/components/sportTicket/sportTicketSlice';
import { useInternalSession } from 'hooks/useInternalSession';
import { BonusCarrelloDataDto } from 'lib/api/sport/cartBonusResponseApi';
import { WalletResponseApi } from 'lib/api/walletResponseApi';
import { WalletHappybetResponseApi } from 'sites/happybet/lib/api/walletHappybetResponseApi';
import { purgeNulls } from 'utility/functions';
import { toggleCarrello } from './carrelloSlice';
import { CarrelloTipo } from './types';
import { setUpdateWalletTimestamp } from 'features/dashboard/dashboardSlice';

export const useIsInCart = (keyToCheck?: string) => {
  const { ambiente } = useAmbienteContext();
  const esitiInCart = useTypedSelector((state) => {
    switch (ambiente) {
      case 'ippica':
        return state.ippicaTicket.esiti;
      case 'virtual-corse':
        return state.virtualTicket.esiti;
      case 'virtual-sport':
        return state.virtualTicket.esiti;
      case 'sport':
      default:
        return state.sportTicket.esiti;
    }
  });

  if (!keyToCheck) return false;

  return esitiInCart[keyToCheck] ? true : false;
};

export const useCartToggleHandler = (
  keyToToggle?: string,
  cartBehavior: 'open' | 'close' | 'none' = 'open',
  stateInfo?: InfoAmbiente
) => {
  const dispatch = useAppDispatch();
  const infoAmbiente = useAmbienteContext();
  const isFullCart = useScreenWidth(enComparer.greater | enComparer.equal, breakpoint.md);
  const isActiveInCart = useIsInCart(keyToToggle);
  const info = stateInfo ?? infoAmbiente;

  const onCartToggleHandler = () => {
    if (!keyToToggle) throw new Error('Esito is undefined!');

    if (isActiveInCart) {
      dispatch(removeFromCartByKey({ esitoKey: keyToToggle, ...info }));
    } else {
      dispatch(addToCartByKey({ esitoKey: keyToToggle, ...info }));
    }

    if (cartBehavior !== 'none') {
      dispatch(
        toggleCarrello({
          isOpen: cartBehavior === 'open',
          isFullCart,
        })
      );
    }
  };

  return onCartToggleHandler;
};

export const useHasMultipleCashes = () => {
  const isActiveVirtual = useTypedSelector(selectIsActiveVirtual);
  const isActiveIppica = useTypedSelector(selectIsActiveIppica);
  return isActiveVirtual || isActiveIppica;
};

export const useCarrelloBonus = () => {
  const tipo = useTypedSelector(selectTipo);
  const slice = useTypedSelector((state) => selectSliceTicketByType(state, tipo));

  const { session, status } = useInternalSession();
  const [getUserSession] = useLazyGetSessionDataQuery();
  const [getSportOfflineBonus] = useLazyGetCartBonusQuery();
  const [getVirtualBonus] = useLazyGetVirtualBonusQuery();

  const dispatch = useAppDispatch();

  const retrieveBonuses = useCallback(async () => {
    if (status === 'loading') return;

    if (status === 'authenticated') {
      const { bonusCarrelloKey } = session?.user!;
      const {
        BONUS: bonus,
        BONUS_S: bonusSistema,
        BONUS_PSIP2: bonusIppica,
        BONUS_M: bonusMultipla,
      } = (await getUserSession(bonusCarrelloKey).unwrap()) as BonusCarrelloDataDto;
      dispatch(
        bonusConfigurationAdded({
          '1': bonus || bonusMultipla,
          '101': bonusSistema,
        })
      );
      dispatch(ippicaBonusConfiguration(bonusIppica));
    } else if (status === 'unauthenticated') {
      getSportOfflineBonus();
    }
  }, [dispatch, getSportOfflineBonus, getUserSession, session, status]);

  useEffect(() => {
    retrieveBonuses();
    if (isSnaiSite) getVirtualBonus();
    dispatch(updateTicket(slice));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
};

export const useIsSaldoGiocabile = () => {
  const tipo = useTypedSelector(selectTipo);
  const { isAuthenticated, status, session } = useInternalSession();
  const dispatch = useAppDispatch();

  const now = useMemo(() => Date.now(), []);
  const saldoFromState = useTypedSelector(selectSaldo);
  const bonusGoldFromState = useTypedSelector(selectBonusGold);
  const updateWalletTimestamp = useTypedSelector(selectUpdateWalletTimestamp);

  const [trigger, walletResponse] = useLazyGetWalletQuery({
    selectFromResult: ({ data }) => {
      const responseSnai = isSnaiSite && data ? (data as WalletResponseApi) : undefined;
      const responseHappybet = !isSnaiSite && data ? (data as WalletHappybetResponseApi) : undefined;
      if (isSnaiSite)
        return {
          saldo: responseSnai?.saldo,
          bonusGold: responseSnai?.dettaglio.find((item) => item.des_saldo === 'Bonus Wagering')?.saldo,
        };
      else
        return {
          saldo: responseHappybet?.saldoTotSport,
          bonusGold: responseHappybet?.listSport.find((item) => item.tipo === 'bonus_wg')?.saldo,
        };
    },
  });

  const amount = useMemo(() => {
    if (session) {
      return saldoFromState;
    }
    return 0;
  }, [saldoFromState, session]);

  useEffect(() => {
    if (!isAuthenticated || status === 'loading') return;
    if (!!updateWalletTimestamp && now >= updateWalletTimestamp) trigger(undefined);
  }, [isAuthenticated, status, trigger, now, updateWalletTimestamp]);

  useEffect(() => {
    if (purgeNulls(walletResponse)) {
      dispatch(setUpdateWalletTimestamp(addMinutes(new Date(), 5).getTime()));
    }
  }, [walletResponse, dispatch]);

  const isSistema = useTypedSelector((state) => selectIsSistemaByTicket(state, tipo));
  const totalePuntataScommesseSistema = useTypedSelector((state) =>
    selectTotalePuntataScommesseSistemaByTicket(state, tipo)
  );
  const puntataSingolaMultipla = useTypedSelector((state) => selectPuntataSingolaMultiplaByTicketType(state, tipo));
  const puntata = isSistema ? totalePuntataScommesseSistema : puntataSingolaMultipla * 100;
  const bonus: number = bonusGoldFromState ? +bonusGoldFromState : 0;

  return amount !== undefined && puntata <= amount + (tipo === CarrelloTipo.Sport ? bonus : 0);
};
