import { createContext, useContext } from 'react';
import raise from 'utility/raise';

export type InfoAmbiente = {
  pathScommessa: string[];
  ambiente: 'ippica' | 'virtual-sport' | 'virtual-corse' | 'sport';
};

export const AmbienteContext = createContext<InfoAmbiente | null>(null);

export const useAmbienteContext = () => {
  const selectAmbiente =
    useContext(AmbienteContext) ??
    raise('useAmbienteContext must be used inside a AmbienteContext providing a valid selector');
  return selectAmbiente;
};
