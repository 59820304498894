import { Icon, IconsEnum } from 'components/Icons';
import { CountdownTimer } from 'components/countdownTimer';
import styles from './TimingLivePreMatch.module.scss';

export type TimingLivePreMatchProps = {
  value?: string;
  isLive?: boolean;
  minutes?: number;
  seconds?: number;
};

export const TimingLivePreMatch = ({ ...props }: TimingLivePreMatchProps) => {
  return (
    <div className={styles.dateAndTimeWrapper}>
      {props.isLive && !props.minutes && !props.seconds && (
        <Icon iconId={IconsEnum.LIVE} color="var(--color-accent)" className={styles.iconLive}></Icon>
      )}
      <div className={styles.wrapperLabel}>
        {props.isLive && !props.minutes && !props.seconds && <strong className={styles.liveLabel}>LIVE</strong>}
        {props.value && !props.minutes && !props.seconds && (
          <span
            className={`${props.isLive ? styles.labelLive : styles.label}`}
            dangerouslySetInnerHTML={{ __html: props.value }}
          />
        )}
        {(props.minutes || props.seconds) && (
          <CountdownTimer minutes={props.minutes ? props.minutes : 0} seconds={props.seconds ? props.seconds : 0} />
        )}
      </div>
    </div>
  );
};
