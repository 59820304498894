import { AnchorLink } from 'components/anchorLink';
import { useAvvenimentoLink } from 'features/sport/hooks';

export interface ScommessaAvvenimentoLinkProps {
  children?: JSX.Element | undefined;
  className?: string;
  avvenimentoKey?: string;
}

export const ScommessaAvvenimentoLink = ({
  children,
  className,
  avvenimentoKey,
}: ScommessaAvvenimentoLinkProps): JSX.Element => {
  const { found, longUrl, shortUrl, first } = useAvvenimentoLink(avvenimentoKey);

  if (found) {
    return (
      <AnchorLink href={first ? shortUrl : longUrl} className={className}>
        {children}
      </AnchorLink>
    );
  }

  return <div className={className}>{children}</div>;
};
