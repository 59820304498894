import { AnchorLink } from 'components/anchorLink';
import { memo } from 'react';
import { Icon, IconsEnum } from '../Icons';
import styles from './SectionTitle.module.scss';

export type SectionTitleProps = {
  title: string;
  url?: string;
  subtitle?: string;
  noPadding?: boolean;
  className?: string;
  isAlignCenter?: boolean;
};

export const SectionTitle: React.FC<SectionTitleProps> = memo(function SectionTitle({
  title,
  url,
  subtitle,
  noPadding,
  className,
  isAlignCenter,
}: SectionTitleProps) {
  return (
    <div
      className={`${noPadding ? '' : styles.titleContainer} ${className ? className : ''} ${
        isAlignCenter ? styles.alignCenter : ''
      }`}
    >
      {url ? (
        <h2 className={styles.title}>
          <AnchorLink href={`/${url}`} className={styles.link}>
            {title}
            <Icon iconId={IconsEnum.ARROW} className={styles.titleIcon} color="var(--color-primary)" />
          </AnchorLink>
        </h2>
      ) : (
        <h2 className={styles.title}>{title}</h2>
      )}

      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
    </div>
  );
});
