import { BannerHeroSkeleton } from '../bannerHero';
import { BannerLandscape } from '.';
import React from 'react';
import { useGetPageBannersQuery } from 'features/api/promoEngineApiSlice';

export const BannerLandscapeWrapper = ({
  pageId,
  promoNumber,
  removePaddingLeft,
  removePaddingRight,
}: {
  pageId: string;
  promoNumber: number;
  removePaddingLeft?: boolean;
  removePaddingRight?: boolean;
}) => {
  const { data, isLoading, isError } = useGetPageBannersQuery(pageId);
  const banner = Reflect.get(data?.pageBannerList ?? {}, promoNumber) ?? undefined;

  if (isLoading) {
    return <BannerHeroSkeleton />;
  }

  if (isError || !banner) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <React.Fragment />;
  }

  return (
    <BannerLandscape data={banner} removePaddingLeft={removePaddingLeft} removePaddingRight={removePaddingRight} />
  );
};
