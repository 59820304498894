import { createContext, useContext } from 'react';

export type InfoGroup = {
  avvenimentoKey: string;
  scommessaGroupKey: string;
};

export const GroupContext = createContext<InfoGroup | null>(null);

const emptyContext = {} as InfoGroup;

export const useGroupContext = () => {
  const selectGroupContext = useContext(GroupContext);
  return selectGroupContext ?? emptyContext;
};
