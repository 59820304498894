export { BannerLandscapeWrapper } from './BannerLandscapeWrapper';

import { enComparer, useScreenWidth } from 'hooks/useScreenWidth';

import { BannerHeroSkeleton } from '../bannerHero';
import { BannerLandscapeProps } from './BannerLandscape';
import { breakpoint } from 'utility/constant';
import { useLazyLoad } from 'hooks';
import { useMemo } from 'react';

export const BannerLandscape = (props: BannerLandscapeProps) => {
  const placeholder = <BannerHeroSkeleton />;

  const isMobile = useScreenWidth(enComparer.less, breakpoint.md);

  const height = useMemo(() => (isMobile ? '22.4rem' : '30.4rem'), [isMobile]);

  return useLazyLoad({
    props,
    height,
    placeholder,
    doLoad: () => import('components/banner/bannerLandscape/BannerLandscape').then((mod) => mod.BannerLandscape),
  });
};
