import { AppFragment, AppFragmentType } from 'enums/path-fragment';
import { LiveFiltersStatus, enFase, enGolNoGol, enStatoMatch } from 'features/live/liveFilters';
import { AvvenimentoList, InfoTipoScommessa } from 'lib/api/sport/sportScommesseBySlugResponse';
import { SportsAvvenimentoEsposto, SportsCompetitor, SportsPiuGiocateTabDto } from 'types/swagger';
import { isMatch, isTruthy, toSportUrlCase } from 'utility/functions';
import { matchesOrario, matchesQuote } from './components/sportNav/utils';
import {
  selectAvvenimentiFactory,
  selectAvvenimentoFactory,
  selectAvvenimentoListFactory,
  selectCompetitorFactory,
  selectCurrentPath,
  selectDescrizioniTipoScommessaFactory,
  selectEsitoByScommessaFactory,
  selectEsitoFactory,
  selectEsitoFromPlayer,
  selectEsitoMapFactory,
  selectInfoAggiuntivaFactory,
  selectInfoAggiuntivaMapFactory,
  selectInfoGroupScommessaFactory,
  selectInfoTipoScommessaFactory,
  selectInfoTipoScommessaListFactory,
  selectIsFilterChanged,
  selectIsScommessaLoadingFactory,
  selectPlayer,
  selectPlayerFromInfoAggFactory,
  selectPlayersFactory,
  selectScommessaFactory,
  selectScommessaResponseFactory,
  selectScommessaStatusFactory,
  selectSelectedFiltersFactory,
  selectTabListFactory,
  selectTabListResponseFactory,
} from './selectors';
import { AvvenimentoLinkType, InfoTipoListPayload, SportFilters } from './types';

import { useAmbienteContext } from 'context/Ambiente';
import { useGroupContext } from 'context/Group';
import { shallowEqual } from 'fast-equals';
import { ApiStatus } from 'features/api/thunkUtils';
import { useTypedSelector } from 'lib/centralStore';
import { useMemo } from 'react';
import { getUrlManifestazioneByAvvenimento } from '.';
import { KeyManagerSport } from './utils/keyManager';

export const useIsPageOfType = (fragment: AppFragmentType) => {
  const path = useTypedSelector(selectCurrentPath);
  return isMatch(path, `^(/*)${fragment}/`);
};

export const useIsLive = () => {
  return useIsPageOfType(AppFragment.Live);
};

export interface SportsAvvenimentoType extends SportsAvvenimentoEsposto {
  urlManifestazione?: string;
}

export const useAvvenimento = (keyAvvenimento: string = '~nothing'): SportsAvvenimentoType => {
  const { pathScommessa } = useAmbienteContext();
  const selectAvvenimento = useMemo(
    () => selectAvvenimentoFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const avvenimento = useTypedSelector((state) => selectAvvenimento(state, keyAvvenimento));

  if (avvenimento) {
    Reflect.set(avvenimento, 'urlManifestazione', getUrlManifestazioneByAvvenimento(avvenimento));
  }

  return avvenimento as SportsAvvenimentoType;
};

export const useAvvenimenti = (): AvvenimentoList => {
  const { pathScommessa } = useAmbienteContext();
  const selectAvvenimentoList = useMemo(
    () => selectAvvenimentoListFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const result = useTypedSelector(selectAvvenimentoList);

  return result ?? [];
};

export const useCompetitor = (avvenimentoKey: string, competitor: 1 | 2, statePath?: string[]) => {
  const { pathScommessa: path } = useAmbienteContext();
  const selectCompetitor = useMemo(
    () => selectCompetitorFactory(selectScommessaResponseFactory(statePath ?? path), competitor),
    [competitor, path, statePath]
  );

  return useTypedSelector((state) => selectCompetitor(state, avvenimentoKey));
};

export const useEsitoByPlayerName = (playerName: string, scommessaKey: string, statePath?: string[]) => {
  const { pathScommessa: path } = useAmbienteContext();
  const selectEsitoList = useMemo(
    () => selectEsitoFromPlayer(selectScommessaResponseFactory(statePath ?? path)),
    [path, statePath]
  );

  return useTypedSelector((state) => selectEsitoList(state, playerName, scommessaKey));
};

export const usePlayer = (playerName: string, scommessaKey: string) => {
  const { pathScommessa: path } = useAmbienteContext();
  const selectPlayerFactory = useMemo(() => selectPlayer(selectScommessaResponseFactory(path)), [path]);

  return useTypedSelector((state) => selectPlayerFactory(state, playerName, scommessaKey));
};

export const useAvvenimentoByPath = (keyAvvenimento: string, path: string[]) => {
  const selectAvvenimento = useMemo(() => selectAvvenimentoFactory(selectScommessaResponseFactory(path)), [path]);
  const avvenimento = useTypedSelector((state) => selectAvvenimento(state, keyAvvenimento));
  return avvenimento;
};

const avvenimentoLinkFallback: AvvenimentoLinkType = {
  shortUrl: '',
  longUrl: '',
  found: false,
  first: true,
};

export const useAvvenimentoLink = (keyAvvenimento: string = '~nothing') => {
  const { pathScommessa } = useAmbienteContext();
  const selectAvvenimentoList = useMemo(
    () => selectAvvenimentoListFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );

  const avvenimenti = useTypedSelector(selectAvvenimentoList);

  const { list, longUrl } = useMemo(() => {
    return {
      list: avvenimenti?.map((x) => x.slug),
      longUrl: avvenimenti?.find((x) => x.key === keyAvvenimento)?.slug,
    };
  }, [avvenimenti, keyAvvenimento]);

  if (longUrl) {
    const rgx = new RegExp(`\/${keyAvvenimento}$`, 'gim');
    const shortUrl = longUrl.replace(rgx, '');

    const first =
      (list ?? [])
        .filter((x) => `${x}`.startsWith(`${shortUrl}/`))
        .sort()
        .indexOf(longUrl) < 1;

    return {
      shortUrl: toSportUrlCase(shortUrl),
      longUrl: toSportUrlCase(longUrl),
      found: true,
      first,
    };
  }

  return avvenimentoLinkFallback;
};

export const useAvvenimentoLinkByPath = (keyAvvenimento: string, path: string[]) => {
  const selectAvvenimentoList = useMemo(
    () => selectAvvenimentoListFactory(selectScommessaResponseFactory(path)),
    [path]
  );

  const avvenimenti = useTypedSelector(selectAvvenimentoList);

  const { list, longUrl } = useMemo(() => {
    return {
      list: avvenimenti?.map((x) => x.slug),
      longUrl: avvenimenti?.find((x) => x.key === keyAvvenimento)?.slug,
    };
  }, [avvenimenti, keyAvvenimento]);

  if (longUrl) {
    const rgx = new RegExp(`\/${keyAvvenimento}$`, 'gim');
    const shortUrl = longUrl.replace(rgx, '');

    const first =
      (list ?? [])
        .filter((x) => `${x}`.startsWith(`${shortUrl}/`))
        .sort()
        .indexOf(longUrl) < 1;

    return {
      shortUrl: toSportUrlCase(shortUrl),
      longUrl: toSportUrlCase(longUrl),
      found: true,
      first,
    };
  }

  return avvenimentoLinkFallback;
};

export const useAvvenimentoCompetitors = (keyAvvenimento?: string): Array<SportsCompetitor> => {
  const { firstCompetitor, secondCompetitor } = useAvvenimento(keyAvvenimento) ?? {};

  return useMemo(() => {
    const competitors: Array<SportsCompetitor> = [];
    if (firstCompetitor) {
      competitors.push(firstCompetitor);
    }
    if (secondCompetitor) {
      competitors.push(secondCompetitor);
    }
    return competitors;
  }, [firstCompetitor, secondCompetitor]);
};

export const useLiveInfo = (keyAvvenimento = '~nothing') => {
  const { pathScommessa } = useAmbienteContext();
  const selectAvvenimento = useMemo(
    () => selectAvvenimentoFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const avvenimento = useTypedSelector((state) => selectAvvenimento(state, keyAvvenimento));
  return avvenimento?.live;
};

export const useInfoTipo = (keyScommessa: string) => {
  const { pathScommessa } = useAmbienteContext();
  const selectInfoTipoScommessa = useMemo(
    () => selectInfoTipoScommessaFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const infoTipoScommessa = useTypedSelector((state) => selectInfoTipoScommessa(state, keyScommessa));

  return infoTipoScommessa as InfoTipoScommessa;
};

export const useInfoTipoByPath = (keyScommessa: string, path: string[]) => {
  const selectInfoTipoScommessa = useMemo(
    () => selectInfoTipoScommessaFactory(selectScommessaResponseFactory(path)),
    [path]
  );
  const infoTipoScommessa = useTypedSelector((state) => selectInfoTipoScommessa(state, keyScommessa));

  return infoTipoScommessa as InfoTipoScommessa;
};

export const useGroup = (groupKey: string) => {
  const { pathScommessa } = useAmbienteContext();
  const selectGroupMap = useMemo(
    () => selectInfoGroupScommessaFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  return useTypedSelector((state) => selectGroupMap(state, groupKey));
};

export const useGroupGap = () => {
  const { scommessaGroupKey } = useGroupContext();
  return useMemo(() => {
    const result: Record<string, number> = {};
    if (scommessaGroupKey) {
      scommessaGroupKey
        .replace('group-', '')
        .split('_')
        .forEach((x, index) => {
          result[index + 1] = index + 1;
        });
    }
    return result;
  }, [scommessaGroupKey]);
};

export const useInfoTipoList = (keyScommessa: Array<string | number>): InfoTipoListPayload => {
  const { pathScommessa } = useAmbienteContext();

  const { selectInfoTipoScommessa, selectinfoAggiuntivaMap } = useMemo(() => {
    const selectinfoAggiuntivaMap = selectInfoAggiuntivaMapFactory(selectScommessaResponseFactory(pathScommessa));
    const selectInfoTipoScommessa = selectInfoTipoScommessaListFactory(selectScommessaResponseFactory(pathScommessa));
    return { selectInfoTipoScommessa, selectinfoAggiuntivaMap };
  }, [pathScommessa]);

  const selectorKey: Array<string> = useMemo(() => (keyScommessa ?? []).map((x) => `${x}`), [keyScommessa]);
  const list = useTypedSelector((state) => selectInfoTipoScommessa(state, selectorKey), shallowEqual);
  const infoAgg = useTypedSelector(selectinfoAggiuntivaMap, shallowEqual);
  const infoTipoScommessaList = list.filter((infoTipoScommessa) => !!infoTipoScommessa) as Array<InfoTipoScommessa>;

  const headerSequence: Record<string, Array<number>> = useMemo(() => {
    const result: Record<string, Array<number>> = {};

    const allKeys = Object.keys(infoAgg ?? {});
    for (const { key } of infoTipoScommessaList) {
      if (Array.isArray(result[key!])) continue;
      const tmp = allKeys.find((iaKey) => isMatch(iaKey, `-${(key ?? '').replace('$', '\\$')}$`));
      if (tmp) {
        const item = Reflect.get(infoAgg ?? {}, tmp);
        const list = Reflect.get(item, 'infoAggiuntivaList');
        result[key!] = (list?.[0]?.esitoKeyList ?? [])
          .map((name) => name.split('-').pop())
          .map((x) => Number(x) - 1) as Array<number>;
      }
    }

    return result;
  }, [infoAgg, infoTipoScommessaList]);

  return { headerSequence, infoTipoScommessaList };
};

export const useEsitoMap = () => {
  const { pathScommessa } = useAmbienteContext();
  const selectEsitoMap = useMemo(
    () => selectEsitoMapFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const mapEsiti = useTypedSelector(selectEsitoMap);
  return mapEsiti;
};

export const useInfoAggiuntiva = (keyScommessa: string) => {
  const { pathScommessa } = useAmbienteContext();
  const selectInfoAggiuntiva = useMemo(
    () => selectInfoAggiuntivaFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const infoAggiuntivaScommessa = useTypedSelector((state) => selectInfoAggiuntiva(state, keyScommessa));
  return infoAggiuntivaScommessa;
};

export const useInfoAggiuntivaByPath = (keyScommessa: string, path: string[]) => {
  const selectInfoAggiuntiva = useMemo(() => selectInfoAggiuntivaFactory(selectScommessaResponseFactory(path)), [path]);
  const infoAggiuntivaScommessa = useTypedSelector((state) => selectInfoAggiuntiva(state, keyScommessa));
  return infoAggiuntivaScommessa;
};

export const useScommessa = (keyScommessa: string) => {
  const { pathScommessa } = useAmbienteContext();
  const selectScommessa = useMemo(
    () => selectScommessaFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const scommessa = useTypedSelector((state) => selectScommessa(state, keyScommessa));
  return scommessa;
};

export const useScommessaByPath = (keyScommessa: string, path: string[]) => {
  const selectScommessa = useMemo(() => selectScommessaFactory(selectScommessaResponseFactory(path)), [path]);
  const scommessa = useTypedSelector((state) => selectScommessa(state, keyScommessa));
  return scommessa;
};

export const useEsito = (keyEsito?: string) => {
  const { pathScommessa } = useAmbienteContext();
  const selectEsito = useMemo(() => selectEsitoFactory(selectScommessaResponseFactory(pathScommessa)), [pathScommessa]);
  const esito = useTypedSelector((store) => selectEsito(store, keyEsito ?? '~nothing'));
  return esito;
};

export const useEsitoByPath = (keyEsito: string, path: string[]) => {
  const selectEsito = useMemo(() => selectEsitoFactory(selectScommessaResponseFactory(path)), [path]);
  const esito = useTypedSelector((store) => selectEsito(store, keyEsito));
  return esito;
};

export const useEsitoByScommessa = (keyScommessa: string) => {
  const { pathScommessa } = useAmbienteContext();
  const selectEsitoByScommessa = useMemo(
    () => selectEsitoByScommessaFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const esito = useTypedSelector((store) => selectEsitoByScommessa(store, keyScommessa));
  return esito;
};

export const useDescrizioniScommesse = (keys: (string | number)[]) => {
  const { pathScommessa } = useAmbienteContext();
  const selectDescrizioniScommesse = useMemo(
    () => selectDescrizioniTipoScommessaFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const descrizioni = useTypedSelector((state) => selectDescrizioniScommesse(state, keys));
  return descrizioni;
};

export const useScommessaStatus = () => {
  const selectScommessaStatus = useMemo(selectScommessaStatusFactory, []);
  const status = useTypedSelector(selectScommessaStatus);

  return {
    isError: status === ApiStatus.failed,
    isLoading: status === ApiStatus.loading,
  };
};

/**
 * @deprecated in favore di useScommessaStatus
 *
 * */
export const useIsScommessaLoading = () => {
  const selectIsScommessaLoading = useMemo(selectIsScommessaLoadingFactory, []);
  return useTypedSelector(selectIsScommessaLoading);
};

export const useAvvenimentoList = (keys: Array<string>, applySorting = false) => {
  const { pathScommessa } = useAmbienteContext();
  const selectAvvenimentoList = useMemo(
    () => selectAvvenimentoListFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );

  const data = useTypedSelector((state) => selectAvvenimentoList(state));

  return useMemo(() => {
    const result = (data ?? [])
      .filter(({ key }) => !!key && keys.includes(key))
      .filter((item, idx, lst) => lst?.findIndex((x) => x.key === item.key) === idx);

    if (applySorting) {
      return result.sort((a, b) => {
        const aTime = new Date(a.dataOra ?? '2020-10-10');
        const bTime = new Date(b.dataOra ?? '2020-10-10');
        return Number(bTime) > Number(aTime) ? -1 : 1;
      });
    }

    return result;
  }, [applySorting, data, keys]);
};

// evalLiveFilters is used as "array.filter" function, so, it should identify the "false" cases to remove occurrence from the list
// matches passing all checks are the one to keep
const evalLiveFilters = (avvenimento: SportsAvvenimentoEsposto, filter: LiveFiltersStatus): boolean => {
  const live = Reflect.get(avvenimento ?? {}, 'live');

  if (live) {
    const { firstCompetitor, secondCompetitor } = live.score ?? {};
    const { minutaggio, sommaGol, diffGol, fase, statoMatch, golNoGol } = filter ?? {};

    const n1 = Number(firstCompetitor);
    const n2 = Number(secondCompetitor);

    if (isTruthy(fase?.length)) {
      // check by fase is applied by
      // list contains for standard check
      let ckFase = fase?.some((x) => isMatch(live.status, x));

      if (fase?.includes(enFase.Other)) {
        // list does not contains for other check
        ckFase =
          ckFase &&
          ([enFase.FirstHalf, enFase.Breack, enFase.SecondHalf].some((x) => isMatch(live.status, x)) ? false : true);
      }

      // return is executed only in ca  se of not match
      // otherwise next condition should be evaluate
      if (!ckFase) return false;
    }

    if (isTruthy(diffGol?.length)) {
      // get goals diff
      let diff = n1 > n2 ? n1 - n2 : n2 - n1;
      if (diff > 4) {
        // since "> 3" is assigned to 4, limit diff to 4
        diff = 4;
      }

      // if selected values does not include diff value: found the exit case
      // otherwise next condition should be evaluate
      if (!diffGol?.includes(diff)) {
        return false;
      }
    }

    if (isTruthy(sommaGol?.length)) {
      // get goals sum
      let somma = n1 + n2;
      if (somma > 4) {
        // since "> 3" is assigned to 4, limit sum to 4
        somma = 4;
      }

      // if selected values does not include sum value: found the exit case
      // otherwise next condition should be evaluate
      if (!sommaGol?.includes(somma)) {
        return false;
      }
    }

    if (isTruthy(statoMatch?.length)) {
      let ck1 = true;
      if (statoMatch?.includes(enStatoMatch.Home)) {
        ck1 = n1 > n2;
      }

      let ck2 = true;
      if (statoMatch?.includes(enStatoMatch.Balance)) {
        ck2 = n1 === n2;
      }

      let ck3 = true;
      if (statoMatch?.includes(enStatoMatch.Guest)) {
        ck3 = n1 < n2;
      }

      let ckStatoMatch = ck1 || ck2 || ck3;

      // if selected result does not match result: found the exit case
      // otherwise next condition should be evaluate
      if (!ckStatoMatch) {
        return false;
      }
    }

    if ((golNoGol ?? []).length === 1) {
      // goal / no-goal is evaluated only if we have 1 item selected (2 means "all" as per 0 selection)
      const selection = golNoGol!.pop();

      // count "zero" occurrence in the results
      const nZero = [n1 ?? 0, n2 ?? 0].filter((x) => x === 0).length;

      switch (selection) {
        case enGolNoGol.NoGoal: {
          // NoGoal means at last one (or both) competitors has 0 goals
          if (nZero === 0) {
            // if counter of zero = 0: found the exit case
            return false;
          }
          break;
        }
        default: {
          // Goal means none of the competitors has 0 goals
          if (nZero > 0) {
            // if counter of zero > 0: found the exit case
            return false;
          }
          break;
        }
      }

      // next condition should be evaluate
    }

    if (isTruthy(minutaggio?.length)) {
      const lst: Array<boolean> = [];
      const time = Number(live.matchTime);

      for (const value of minutaggio ?? []) {
        const { from, to } = value;

        const nTo = Number(to);
        const nFrom = Number(from);

        if (isNaN(time)) {
          if (isNaN(nTo)) {
            if (to === enFase.Breack) {
              if (!isMatch(live.status, enFase.Breack)) {
                lst.push(false);
                continue;
              }
            } else {
              if (!isMatch(live.status, enFase.SecondHalf)) {
                lst.push(false);
                continue;
              }
            }
          }
        } else {
          if (time < nFrom) {
            lst.push(false);
            continue;
          }

          if (isNaN(nTo)) {
            if (to === enFase.Breack) {
              if (![enFase.FirstHalf, enFase.Breack].some((x) => isMatch(live.status, x))) {
                lst.push(false);
                continue;
              }
            } else {
              if (!isMatch(live.status, enFase.SecondHalf)) {
                lst.push(false);
                continue;
              }
            }
          } else if (time > nTo) {
            lst.push(false);
            continue;
          }
        }
        lst.push(true);
      }

      return lst.includes(true);
    }
  }

  return true;
};

const evalEventIsLive = (avvenimento: SportsAvvenimentoEsposto) => {
  const live = Reflect.get(avvenimento ?? {}, 'live');
  if (live) {
    // using status and matchTime as drivers to detect when event goes live
    const { status, matchTime } = live ?? {};
    if (!!status || !!matchTime) return true;
  }

  return false;
};

const evalSportFilters = (avvenimento: SportsAvvenimentoEsposto, filter: SportFilters): boolean => {
  if (evalEventIsLive(avvenimento)) return false;

  let result = true;

  const filtro = Reflect.get(avvenimento ?? {}, 'filtro');
  if (filtro) {
    const { orario, quota } = filter ?? {};
    const ckQuota = quota?.length < 2 || matchesQuote(filtro, quota[0], quota[1]);
    const ckOrario = matchesOrario(filtro, orario ?? 'default');
    result = ckQuota && ckOrario;
  }

  return result;
};

export const useFilteredAvvenimentoList = (avvenimentoList: AvvenimentoList) => {
  const isLive = useIsPageOfType(AppFragment.Live);
  const isSport = useIsPageOfType(AppFragment.Sport);

  const selectSelectedFilters = useMemo(selectSelectedFiltersFactory, []);
  const isFilterChanged = useTypedSelector(selectIsFilterChanged);

  // filters are mutually exclusive
  const { selectedFilters, selectedFiltersLive } = useTypedSelector(selectSelectedFilters, shallowEqual);

  const result = useMemo(() => {
    if (isTruthy(isFilterChanged)) {
      return avvenimentoList?.filter((avvenimento) => {
        if (isLive) {
          return evalLiveFilters(avvenimento, selectedFiltersLive);
        }

        return evalSportFilters(avvenimento, selectedFilters);
      });
    } else if (isSport) {
      return avvenimentoList?.filter((avvenimento) => {
        return evalEventIsLive(avvenimento) ? false : true;
      });
    }
    return avvenimentoList;
  }, [isFilterChanged, isSport, avvenimentoList, isLive, selectedFilters, selectedFiltersLive]);

  return result;
};

export const useFilteredAvvenimentoByKey = (keys: Array<string>) => {
  const { pathScommessa } = useAmbienteContext();
  const { list, selectAvvenimenti } = useMemo(() => {
    const list = (keys ?? []).map((x) => new KeyManagerSport(x).avvenimentoKey);
    const selectAvvenimenti = selectAvvenimentiFactory(selectScommessaResponseFactory(pathScommessa));

    return {
      list,
      selectAvvenimenti,
    };
  }, [pathScommessa, keys]);

  const avvenimenti = useTypedSelector((state) => selectAvvenimenti(state, list));
  const validOnes = useFilteredAvvenimentoList(avvenimenti ?? []);

  const result = useMemo(() => {
    const validKeys = validOnes?.map((x) => x.key);
    return keys.filter((key) => validKeys?.some((x) => isMatch(key, `^${x}(-*)`)));
  }, [validOnes, keys]);

  return result;
};

export const usePlayers = (infoTipoScommessaKeys: Array<string>) => {
  const { pathScommessa: path } = useAmbienteContext();
  const selectPlayers = useMemo(
    () => selectPlayersFactory(selectScommessaResponseFactory(path), infoTipoScommessaKeys),
    [path, infoTipoScommessaKeys]
  );
  const players = useTypedSelector((state) => selectPlayers(state));
  return players;
};

export const usePlayerFromInfoAgg = (scommessaKey: string, infoAggiuntivaHex: string) => {
  const { pathScommessa: path } = useAmbienteContext();
  const selectPlayer = useMemo(
    () => selectPlayerFromInfoAggFactory(selectScommessaResponseFactory(path), scommessaKey, infoAggiuntivaHex),
    [path, scommessaKey, infoAggiuntivaHex]
  );
  return useTypedSelector((state) => selectPlayer(state));
};

export const useFilteredAvvenimentoCount = () => {
  const { pathScommessa } = useAmbienteContext();
  const selectAvvenimentoList = useMemo(() => {
    return selectAvvenimentoListFactory(selectScommessaResponseFactory(pathScommessa));
  }, [pathScommessa]);

  const avvenimenti = useTypedSelector(selectAvvenimentoList);
  const validOnes = useFilteredAvvenimentoList(avvenimenti ?? []);

  const keys = useMemo(() => {
    return validOnes?.map((x) => x?.key)?.filter((x, idx, lst) => lst.indexOf(x) === idx);
  }, [validOnes]);

  return { keys, counter: keys?.length ?? 0 };
};

/**
 * Data una lista di chiavi scommessa complete, ritorna se almeno una delle scommesse è attiva
 */
export const useIsAtLeastOneActive = (keyList: string[]) => {
  const { pathScommessa } = useAmbienteContext();
  const selectInfoMap = useMemo(
    () => selectInfoAggiuntivaMapFactory(selectScommessaResponseFactory(pathScommessa)),
    [pathScommessa]
  );
  const isAtLeastOneActive = useTypedSelector((state) => {
    const infoAggMap = selectInfoMap(state);
    return keyList.some((key) => {
      const infoAgg = infoAggMap?.[key];
      if (!infoAgg) return false;
      return infoAgg?.infoAggiuntivaList?.some((el) => el.isActive);
    });
  });
  return isAtLeastOneActive;
};

export const useInfoTipoScommessaInformation = (infoTipoScommesseKeyListTab: Array<string>) => {
  const { infoTipoScommessaList } = useInfoTipoList(infoTipoScommesseKeyListTab);
  const infoTipoScommessaKeyList = infoTipoScommessaList.filter(({ key }) => !!key).map(({ key }) => key!);
  const infoTipoScommessaDescription = useDescrizioniScommesse(infoTipoScommessaKeyList);

  return {
    infoTipoScommessaList,
    infoTipoScommessaKeyList,
    infoTipoScommessaDescription,
  };
};

export const useTabList = (): Array<SportsPiuGiocateTabDto> => {
  const { pathScommessa } = useAmbienteContext();
  const stateSlice = useMemo(() => selectTabListResponseFactory(pathScommessa), [pathScommessa]);
  const selectTabList = useMemo(() => selectTabListFactory(stateSlice), [stateSlice]);
  const tabList = useTypedSelector(selectTabList);
  return tabList as Array<SportsPiuGiocateTabDto>;
};
