import { CountDownDisplayer } from 'components/countDownDisplayer';
import { Countdown } from 'components/countdown';
import { character } from 'utility/constant';
import styles from './CountdownTimer.module.scss';

export type CountdownTimerProps = {
  minutes: number;
  seconds: number;
  className?: string;
  children?: React.ReactNode;
};

export const CountdownTimer = ({ minutes, seconds, children, className }: CountdownTimerProps) => {
  const hasZeroBefore = (num: number | string) => {
    if (+num < 10) {
      return '0' + num;
    }
    return num;
  };

  return (
    <Countdown className={`${styles.container} ${className ? className : ''}`}>
      {(seconds != 0 || minutes != 0) && character.minus}
      <CountDownDisplayer>
        {children && minutes === 0 && seconds === 0 ? (
          { children }
        ) : (
          <p className={`${styles.text} ${className ? className : ''}`}>
            {minutes > 0 ? hasZeroBefore(minutes) : '00'}
            {character.colon}
            {seconds > 0 ? hasZeroBefore(seconds) : '00'}
          </p>
        )}
      </CountDownDisplayer>
    </Countdown>
  );
};
