import { isEqual } from 'lodash';
import { memo, useRef } from 'react';
import { FreeMode, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useHideNavigationCarousel } from '../../../hooks/useHideNavigationCarousel';
import { breakpoint } from '../../../utility/constant';
import { WithNumberCardType } from '../../cards/withNumberCard';
import { SectionTitleType } from '../../sectionTitle';
import { SectionTitle } from '../../sectionTitle/SectionTitle';
import { Wrapper } from '../../wrapper';
import { CardType, ConditionalCard } from '../components/conditionalCard/ConditionalCard';
import styles from './Top10Carousel.module.scss';

export type Top10CarouselProps = Omit<SectionTitleType, 'noPadding' | 'className'> & {
  listItem: WithNumberCardType[];
  typeOfItem: CardType;
};

export const Top10Carousel: React.FC<Top10CarouselProps> = memo(
  function Top10Carousel({ title, subtitle, url, listItem, typeOfItem }: Top10CarouselProps) {
    const swiperRef = useRef<any>(null);
    let { isScrollable, swiperSlideRef } = useHideNavigationCarousel({ className: 'disabled' });
    return (
      <Wrapper noPaddingLeft noPaddingRight bgColorTransparent>
        {title && <SectionTitle title={title} subtitle={subtitle} url={url} />}
        <div className={styles.container}>
          <Swiper
            onInit={(swiper) => (swiperSlideRef.current = swiper.el)}
            className={styles.carousel}
            spaceBetween={12}
            grabCursor
            slidesPerView={'auto'}
            slidesOffsetBefore={20}
            slidesOffsetAfter={20}
            modules={[Navigation, FreeMode, Mousewheel]}
            updateOnWindowResize
            watchOverflow
            freeMode={{
              enabled: true,
              minimumVelocity: 0.2,
            }}
            mousewheel={{ forceToAxis: true }}
            navigation={isScrollable ? true : false}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            breakpoints={{
              [breakpoint.sm]: {
                slidesOffsetBefore: 32,
                slidesOffsetAfter: 32,
              },
              [breakpoint.lg]: {
                grabCursor: false,
                spaceBetween: 24,
                slidesOffsetBefore: 149,
                simulateTouch: false,
                allowTouchMove: false,
              },
            }}
          >
            {listItem &&
              listItem.map((item, index) => {
                if (index < 10) {
                  return (
                    <SwiperSlide className={styles.slide} key={item.slug}>
                      <ConditionalCard
                        type={typeOfItem}
                        item={item}
                        position={index + 1 < 10 ? `0${index + 1}` : `${index + 1}`}
                      />
                    </SwiperSlide>
                  );
                }
                return;
              })}
          </Swiper>
        </div>
      </Wrapper>
    );
  },
  function areEqual(prevProps, nextProps) {
    return (
      prevProps.title === nextProps.title &&
      prevProps.subtitle === nextProps.subtitle &&
      prevProps.url === nextProps.url &&
      isEqual(prevProps.listItem, nextProps.listItem) &&
      prevProps.typeOfItem === nextProps.typeOfItem
    );
  }
);
